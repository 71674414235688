.custom_keyboard {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #d1d5db;
  backdrop-filter: blur(54.4px);

  padding: 6px;
  padding-bottom: 44px;
  z-index: 1000;

  display: grid;
  gap: 6.5px;

  grid-template-columns: repeat(3, 1fr);
  user-select: none;
}

.key {
  border: none;
  height: 48px;
  filter: drop-shadow(0px 1px 0px #898a8d);
  background-color: #fcfcfe;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #000;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}

.zero {
  grid-column: 2 / 3;
}

.backspace {
  background-color: transparent;
  border: none;
}

.key:active {
  opacity: 0.6;
}
