.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 376px;
}

.title {
  margin: 0;
  font-size: 30px;
  font-weight: 500;
  line-height: 38px;
  text-align: center;
  color: #393E46;
  margin-bottom: 40px;
}

.icon {
  width: 150px;
  margin-bottom: 24px;
}